import { SurveyConfig } from 'types';
import blockFromMetricsConfig from 'utils/blockFromMetricsConfig';

import blockFidelitySchool from 'surveys/common/block-fidelity-school';
import blockDemographicsRaceGender from 'surveys/common/block-race-gender23';

import SurveyIntroduction from './SurveyIntroduction';
import SurveyComplete from './SurveyComplete';

export const catalyze24: SurveyConfig = {
  name: 'Catalyze',
  label: 'catalyze24',
  survey: [
    {
      component: 'Page',
      label: 'introduction',
      progress: 1,
      props: {
        waitBeforeNext: true,
      },
      questions: [
        {
          label: 'intro_your_opinion',
          component: SurveyIntroduction,
          props: {},
        },
      ],
    },

    blockFromMetricsConfig(
      'catalyze24',
      'effective-systems',
      'Effective Systems',
      'effective-systems',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'collective-vision',
      'Collective Vision',
      'collective-vision',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'trusting-community',
      'Trusting Community',
      'trusting-community',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'learning-culture',
      'Learning Culture',
      'learning-culture',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'inclusive-leadership',
      'Inclusive Leadership',
      'inclusive-leadership',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'transformative-equity',
      'Transformative Equity',
      'transformative-equity',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,

    // Professional Learning (PLCPM) metric set.
    // Metric names are not set because we expect them to come from
    // @perts/config.
    blockFromMetricsConfig(
      'catalyze24',
      'plc-meeting-regularity',
      undefined,
      'plc-meeting-regularity',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-teaching-learning-focus',
      undefined,
      'plc-teaching-learning-focus',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-teacher-observations',
      undefined,
      'plc-teacher-observations',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-joint-lesson-planning',
      undefined,
      'plc-joint-lesson-planning',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-review-student-work',
      undefined,
      'plc-review-student-work',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-well-organized-meetings',
      undefined,
      'plc-well-organized-meetings',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-coherence-over-time',
      undefined,
      'plc-coherence-over-time',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-teacher-teacher-trust',
      undefined,
      'plc-teacher-teacher-trust',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-aligned-goals',
      undefined,
      'plc-aligned-goals',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-adequate-resources',
      undefined,
      'plc-adequate-resources',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-supportive-leadership',
      undefined,
      'plc-supportive-leadership',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-equity-responsive',
      undefined,
      'plc-equity-responsive',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    blockFromMetricsConfig(
      'catalyze24',
      'plc-curriculum-use',
      undefined,
      'plc-curriculum-use',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,
    // Designed to be last b/c it has a "Want to tell us anything else"
    // summative question.
    blockFromMetricsConfig(
      'catalyze24',
      'plc-impact-on-practice',
      undefined,
      'plc-impact-on-practice',
      {
        contextReminderProp: 'class_name',
        waitBeforeNext: true,
      },
    ).block,

    blockFidelitySchool,
    blockDemographicsRaceGender,
    {
      component: 'Page',
      label: 'thankyou',
      progress: 100,
      updateParticipantData: {
        pdKey: 'saw_demographics',
        pdValue: 'true',
      },
      questions: [
        {
          label: 'surveycomplete',
          component: SurveyComplete,
          props: {},
        },
      ],
    },
  ],
};
