// Helper component that wraps up the boilerplate of providing the locale
// messages to a TextField Question and selecting which message will be used
// as the question label based on current locale.
//
// Example usage:
//
// import locales from 'i18n/locales';
//
// const messages = {
//   [locales.ENGLISH]: 'Question text in English.',
//   [locales.SPANISH]: 'Question text in Spanish.',
// };
//
// <QuestionOpenResponse
//   label="c_belonging_classmates"
//   messages={messages}
//   />

// TODO: this is nearly a duplicate of QuestionLikertScale, and should be more
// DRY.

import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LocaleContext } from 'i18n/locales';
import { Field as FormikField } from 'formik';
import { FlexItem, FlexRow } from 'perts-ui';
import { Question, TextField } from 'components';

const QuestionOpenResponse = ({ label, messages = {}, ...rest }) => {
  const { surveyLabel } = useParams();
  const { locale } = useContext(LocaleContext);

  // When no message has been defined for the requested locale, fallback to
  // displaying the question label for development purposes.
  const questionLabel = (messages && messages[locale]) || label;

  if (questionLabel === label) {
    // If we haven't defined a message for the requested locale, it's probably a
    // mistake. Notify so we can get it added.
    console.error(
      `Question ${label} (${surveyLabel}) has no "${locale}" i18n message assigned.`,
    );
  }

  return (
    <>
      <Question.Title>{questionLabel}</Question.Title>

      {/*
        NOTE: this prevents the additional open response questions (prefixed
        "or_") from appearing, because it does not use `Field` from
        'components'. This makes sense because this is already an open response
        question on its own.
      */}
      <Question.Content>
        <FlexRow>
          <FlexItem>
            <FormikField
              id={label}
              name={label}
              component={TextField}
              label={null}
              multiline
              rows="4"
              data-test="open-response-field"
              {...rest}
            />
          </FlexItem>
        </FlexRow>
      </Question.Content>
    </>
  );
};

export default QuestionOpenResponse;
