import { merge } from 'lodash';
import { getProgramMetricConfig, QuestionType } from '@perts/config';
import { SurveyBlock, SurveyBlockPage, SurveyPageControls } from 'types';
import { questionComponentMap } from 'components';
import whenLearningCondition from './whenLearningCondition';

const blockFromMetricsConfig = (
  surveyLabel: string,
  metricLabel: string,
  metricName: string | undefined,
  pageLabel: string,
  props: SurveyPageControls = {},
) => {
  const programMetricConfig = getProgramMetricConfig(surveyLabel);
  const metricConfig = programMetricConfig.metricIndex[metricLabel];
  const metricConfigItems = metricConfig.items;

  const config = {
    label: metricLabel,
    name: metricConfig.name || metricName,
  };

  if (!config.name) {
    throw new Error(`Metric name not defined: ${surveyLabel} ${metricLabel}`);
  }

  const block: SurveyBlock = {
    component: 'Block',
    label: pageLabel,
    props: merge(
      {},
      {
        showWhen: whenLearningCondition(config.label),
        waitBeforeNext: true,
      },
      props,
    ),
    pages: metricConfigItems.map(
      (metric): SurveyBlockPage => ({
        component: 'Page',
        questions: [
          {
            component:
              questionComponentMap[metric.questionType || QuestionType.LIKERT],
            label: metric.label,
            props: {
              likertN: metric.likertN,
              likertReverse: metric.likertReverse,
              messages: metric.prompts,
              options: metric.options,
            },
          },
        ],
      }),
    ),
  };

  return { config, block };
};

export default blockFromMetricsConfig;
